import {
  SlRating
} from "./chunk.P7L4NI5B.js";

// src/components/rating/rating.ts
var rating_default = SlRating;
SlRating.define("sl-rating");

export {
  rating_default
};
