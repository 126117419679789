import {
  SlOption
} from "./chunk.ORPX2RQE.js";

// src/components/option/option.ts
var option_default = SlOption;
SlOption.define("sl-option");

export {
  option_default
};
