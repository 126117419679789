import {
  SlSpinner
} from "./chunk.ASVYZWRC.js";

// src/components/spinner/spinner.ts
var spinner_default = SlSpinner;
SlSpinner.define("sl-spinner");

export {
  spinner_default
};
