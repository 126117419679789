import {
  SlMenu
} from "./chunk.J6DI62DI.js";

// src/components/menu/menu.ts
var menu_default = SlMenu;
SlMenu.define("sl-menu");

export {
  menu_default
};
